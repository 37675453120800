<template>
  <div class="row">
    <div class="col-md-12 p-0">
      <div class="center-card">
        <div class="center-card-box top-sticky">
          <div class="resturant-details | p-3">
            <div class="del-address w-100">
              <h4 class="mb-2">{{ $t('Add Delivery Address') }}</h4>
              <vue-google-autocomplete v-if="$auth.google_map_key" id="mapnew" class="form-control mb-0" type="text"
                v-model="typeform.address" v-on:placechanged="getAddressData"
                :placeholder="$t('Search for an area or Location name')" :types="['address', 'locality', 'sublocality']"
                :country="$auth.setting.country_code_type" :class="{ 'is-invalid': $v.typeform.address.$error }">
              </vue-google-autocomplete>
              <div v-if="$v.typeform.address.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.address.required">{{ $t('Please enter address') }}.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center-card-box">
          <div class="responsive-map">
            <!-- <GmapMap :center="center" :zoom="18" map-style-id="roadmap" :options="mapOptions" style="width: 100%; height: 300px" ref="mapRef" @click="handleMapClick">
              <GmapMarker :position="marker.position" :clickable="true" :draggable="true" @drag="handleMarkerDrag"  @click="panToMarker" />
            </GmapMap>             -->
            <div v-if="!isMapShow" class="text-center col-md-12 p-0 my-">
              <b-spinner class="align-middle"></b-spinner>
            </div>
            <GoogleMaps v-else :markers="marker.position" :center="center" page="addaddress" :options="mapOptions"
              v-on:onHandleClick="handleMapClick"></GoogleMaps>

          </div>
          <div class="px-3 py-4">
            <div class="review-boxs">
              <div class=" position-relative">
                <h6 class="mb-2">{{ $t('House/Building Name') }}</h6>
                <input class="form-control mb-0 h50" type="text" v-model="$v.typeform.house_name.$model"
                  :placeholder="$t('Enter House/Building Name')" aria-label="House/Building Name"
                  :class="{ 'is-invalid': $v.typeform.house_name.$error }" />
                <div v-if="$v.typeform.house_name.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.house_name.required">{{ $t('Enter House/Building Name') }}.</span>
                </div>
              </div>
              <div class="mt-4 position-relative">
                <h6 class="mb-2">{{ $t('Flat Number/Floor') }}</h6>
                <input class="form-control mb-0 h50" type="text" v-model="typeform.flat_no"
                  :placeholder="$t('Enter Flat/ Floor Number')" aria-label="Flat Number/ Floor" />
              </div>
              <div class="mt-4 position-relative">
                <h6 class="mb-2">{{ $t('Landmark') }}</h6>
                <input class="form-control mb-0 h50" type="text" v-model="typeform.landmark"
                  :placeholder="$t('Enter Landmark')" aria-label="Landmark" />
              </div>
            </div>
            <div class="mt-4">
              <h6 class="mb-3">{{ $t('Set tag for Address') }}</h6>
              <form class="boxed d-flex">
                <input type="radio" id="home" name="Home" value="Home" v-model="typeform.address_clarification" />
                <label for="home">{{ $t('Home') }}</label>

                <input type="radio" id="office" name="office" value="Office" v-model="typeform.address_clarification" />
                <label for="office">{{ $t('Office') }}</label>

                <input type="radio" id="other" name="other" value="Other" v-model="typeform.address_clarification" />
                <label for="other">{{ $t('Other') }}</label>
              </form>
            </div>
          </div>
        </div>
        <div class="center-card-box bottom-sticky">
          <div class="full-btn">
            <b-button variant="primary" disabled v-if="isDisable"><b-spinner small type="grow"></b-spinner>{{
              $t("Loading") }}...</b-button>
            <button v-else @click="geoAddress" class="red-outline-btn">{{ $t('Add New Address') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import GoogleMaps from "../components/GoogleMaps.vue";

export default {
  name: "Add-Address-Components",
  components: {
    VueGoogleAutocomplete,
    GoogleMaps
  },
  data() {
    return {
      marker: { position: { lat: 23, lng: 72 } },
      center: { lat: 23, lng: 72 },
      scaleModal: false,
      mapOptions: {
        disableDefaultUI: true,
      },
      address: "",
      typeform: {
        city: "",
        address_clarification: "Home",
        landmark: "",
        address: "",
        is_langauge: this.$store.state.lang.locale,
        state: "",
        vendor_id: this.$auth.getVendorId(),
        flat_no: "",
        house_name: "",
        latitude: "",
        zip_code: "",
        longitude: "",
      },
      isMapShow: false,
      isDisable: true
    };
  },
  validations: {
    typeform: {
      address: { required },
      house_name: ''
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.$auth && this.$auth.user) {
        this.geolocate();
      } else {
        this.$router.push({ name: "sign-in" });
      }
    }, 500);
  },
  methods: {
    ...mapActions("address", ["newAddress"]),
    ...mapMutations("address", ["primaryAddressMutation"]),

    geolocate() {
      let options = { timeout: 20000 };
      navigator.geolocation.getCurrentPosition(this.showLocation, this.errorHandler, options);
    },

    showLocation(position) {
      this.marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // this.panToMarker();
      this.locateAddress(position.coords.latitude, position.coords.longitude);
      this.center.lat = parseFloat(position.coords.latitude);
      this.center.lng = parseFloat(position.coords.longitude);
      this.isMapShow = true;
    },

    errorHandler(err) {
      this.center.lat = parseFloat(this.$auth.setting.system_latitude);
      this.center.lng = parseFloat(this.$auth.setting.system_longitude);
      this.marker.position.lat = parseFloat(this.$auth.setting.system_latitude);
      this.marker.position.lng = parseFloat(this.$auth.setting.system_longitude);
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locateAddress(e.latLng.lat(), e.latLng.lng());
    },

    locateAddress(lat, lng) {
      const geocoder = new window.google.maps.Geocoder();
      this.isDisable = false;
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.typeform.address = results[0].formatted_address;
          this.address = results[0].formatted_address;
          let addd = results[0].address_components;
          for (const component of addd) {
            const componentType = component.types[0];
            switch (componentType) {
              case "postal_code": {
                this.typeform.zip_code = component.long_name;
                break;
              }

              case "locality":
                this.typeform.city = component.long_name
                break;

              case "administrative_area_level_1": {
                this.typeform.state = component.short_name;
                break;
              }
            }
          }
          this.typeform.latitude = this.marker.position.lat;
          this.typeform.longitude = this.marker.position.lng;
        }
        // else if(status === "OVER_QUERY_LIMIT") {
        //   this.$toasted.error(this.$t("You have exceeded your daily request quota for this API"),{duration: 500});;
        // }
      });
    },

    //Moves the map view port to marker
    // panToMarker() {
    //   this.$refs.mapRef.panTo(this.marker.position);
    //   // this.$refs.mapRef.setZoom(18);
    // },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locateAddress(e.latLng.lat(), e.latLng.lng());
    },

    getAddressData: function (addressData, placeResultData) {
      this.address = placeResultData.formatted_address;
      this.typeform.address = placeResultData.formatted_address;
      this.typeform.latitude = addressData.latitude;
      this.typeform.longitude = addressData.longitude;
      var value = this.address.split(", ");
      var value1 = this.address.split(" ");
      var count = value.length;
      var count1 = value1.length;
      this.typeform.locality = value[count - 4];
      this.typeform.state = value1[count1 - 3];
      this.typeform.city = value[count - 3];
      this.typeform.zip_code = value1[count1 - 2];
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.center.lat = parseFloat(addressData.latitude);
      this.center.lng = parseFloat(addressData.longitude);
      this.marker.position.lat = parseFloat(addressData.latitude);
      this.marker.position.lng = parseFloat(addressData.longitude);
      this.$refs.mapRef.panTo(this.marker.position);
      // $('#scaleModal').modal('hide');
    },

    geoAddress() {
      this.$v.$touch();
      if (this.$v.typeform.$invalid) {
        return;
      } else {
        const fd = new FormData();
        fd.append("city", this.typeform.city);
        fd.append("address_clarification", this.typeform.address_clarification);
        fd.append("landmark", this.typeform.landmark);
        fd.append("address", this.typeform.address);
        fd.append("is_langauge", this.typeform.is_langauge);
        fd.append("state", this.typeform.state);
        fd.append("vendor_id", this.typeform.vendor_id);
        fd.append("flat_no", this.typeform.flat_no);
        fd.append("house_name", this.typeform.house_name);
        fd.append("latitude", this.typeform.latitude);
        fd.append("zip_code", this.typeform.zip_code);
        fd.append("longitude", this.typeform.longitude);
        this.newAddress(fd).then((data) => {
          if (data.code == 200) {
            this.$toasted.success(this.$t("Address added successfully"), { duration: 2000 });
            this.$store.commit("address/primaryAddressMutation", data.Result);
            if (this.$route.name == 'add-address') {
              this.$router.go(-1);
            } else {
              this.$emit("closedAddAddress", data.Result);
              this.typeform.house_name = '';
              this.typeform.flat_no = '';
              this.typeform.landmark = '';
              this.typeform.address_clarification = "Home";
            }
          } else {
            this.$toasted.success(this.$t(data.msg), { duration: 2000 });
          }
        });
      }
    },
  },
};
</script>