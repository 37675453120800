<template>
  <div>
    <header class="index-header cust-navbar-main fixed-top">
      <div class="header-main marquee-header">
        <!-- <marquee behavior="scroll" id="marquee" gap="0" truespeed="" :direction="direction" scrollamount="2" scrolldelay="50" onmouseover="this.stop();" onmouseout="this.start();" v-if="marque_text && marque_text != 'null'">
          <p>{{marque_text}}</p>
        </marquee> -->
        <nav class="navbar navbar-light bg-white align-items-center">
          <div class="d-flex align-items-center cust-navbar-left ms-2">
            <button class="btn border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="offcanvas offcanvas-start border-0" tabindex="-1" id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel">
              <div class="offcanvas-body | p-0">
                <side-bar></side-bar>
              </div>
            </div>
            <div class="delivery-pickup-main d-flex align-items-center" v-if="!is_scrren_hide_mobile">
              <div class="delivery-pickup-lnk me-2"
                v-if="this.$route.name == 'home' && $auth.setting && $auth.setting.is_pickup_enable && !is_scrren_hide_mobile">
                <div class="switch" id="switch">
                  <input name="radio" @click="getDelivery('1')" type="radio" value="1" id="delivery"
                    :checked="isPickupDelivery == 1">
                  <label for="delivery">{{ $t('Delivery') }}</label>
                  <input name="radio" @click="getDelivery('0')" type="radio" value="0" id="pickup"
                    :checked="isPickupDelivery == 0">
                  <label for="pickup" class="right">{{ $t('Takeaway') }}</label>
                  <span class="swtact" :class="this.rightClass || { right: ['0'].includes(isPickupDelivery) }"
                    aria-hidden="true"></span>
                </div>
              </div>
              <a href="javascript:void(0)" @click="deliveryModalAddress"
                class="delivery-link map-location set-address-link "
                v-if="this.$route.name == 'home' && !is_scrren_hide_mobile && isPickupDelivery == '1'">
                <span class="delivery-image">
                  <svg width="20" height="25" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"
                    enable-background="new 0 0 100 100" xml:space="preserve">
                    <g>
                      <path fill="#000000"
                        d="M-293.039-548.582c-3.294,0-5.962-2.669-5.962-5.962v-2.003c0-7.554-6.146-14.699-13.699-14.699h-17.206   c-7.554,0-13.699,6.145-13.699,13.699v12.003c0,3.293-2.669,5.962-5.962,5.962c-3.292,0-5.962-2.669-5.962-5.962v-12.003   c0-14.129,11.495-25.623,25.623-25.623h17.206c14.128,0,25.623,12.494,25.623,26.623v2.003   C-287.077-551.251-289.747-548.582-293.039-548.582z" />
                    </g>
                    <path fill="#000000" stroke="#231F20" stroke-width="20" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="  M-669.54-508.264v-20.134c0-18.213,14.765-32.978,32.978-32.978h28.859c18.213,0,32.978,14.765,32.978,32.978v20.134" />
                    <path fill="#000000"
                      d="M52.768,0C33.749,0,18.333,15.417,18.333,34.434c0,5.977,1.524,11.595,4.203,16.494  C22.618,51.3,49.483,97.97,49.483,97.97c0.677,1.171,1.929,1.896,3.284,1.896c1.354-0.002,2.604-0.725,3.283-1.896  c0,0,26.863-46.669,26.946-47.041c2.68-4.898,4.203-10.518,4.203-16.495C87.2,15.417,71.784,0,52.768,0z M52.768,52.776  c-8.55,0-15.48-6.932-15.48-15.48c0-8.549,6.93-15.479,15.48-15.479c8.548,0,15.478,6.93,15.478,15.479  C68.245,45.845,61.315,52.776,52.768,52.776z" />
                  </svg>
                </span>
                <span v-if="address == ''">{{ $t('Set Location') }}</span>
                <p v-else class="text-truncate d-inline-block d-flex flex-column" v-bind:title="address">
                  <span class="outl-nme">{{ adressClarification }}</span>
                  <span class="outl-addrs">{{ address }}</span>
                </p>
              </a>
              <!---Display store for Single Restaurant--->
              <a href="javascript:void(0)" @click="isStore = true" class="delivery-link map-location set-address-link "
                v-if="this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && $auth.setting.manual_store_selection_by_customer == 1 && this.$route.name == 'home' && !is_scrren_hide_mobile && isPickupDelivery == '0'">
                <span class="delivery-image">
                  <img src="@/assets/images/shop-icon.png" alt="offer" height="25" width="25">
                </span>
                <span v-if="address == ''">{{ $t('Select store') }}</span>
                <p v-else class="text-truncate d-inline-block d-flex flex-column" v-bind:title="address">
                  <span class="outl-nme">{{ $t('Select') }} {{ $auth.setting && $auth.setting.restaurant_name }}</span>
                  <span class="outl-addrs">{{ singleRestaurant.name }}</span>
                </p>
              </a>
            </div>
            <div class="logo-main">
              <router-link to="/" class="desktop-logo">
                <img v-if="$auth.getAppLogo() != ''" class="nav-logo" :src="$auth.getAppLogo()" alt="logo" />
                <div v-else class="logo-container">
                  <div class="logo-holder logo-4">
                    <router-link to="/">
                      <h3>{{ $auth.setting && $auth.setting.app_name }}</h3>
                    </router-link>
                  </div>
                </div>
              </router-link>
              <router-link to="/" class="mobi-logo"> <img class="nav-logo" :src="$auth.getAppLogo()"
                  alt="logo" /></router-link>
            </div>
          </div>
          <div class="d-flex">
            <button type="button" class="btn icon-btn me-2" @click="isStore = true"
              v-if="this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && $auth.setting.manual_store_selection_by_customer == 1 && this.$route.name == 'home' && isPickupDelivery == '1'">
              <img src="@/assets/images/shop-icon.png" alt="offer" height="20" width="20">
            </button>
            <nav-right v-on:toggleCart="navtoggleCart" v-on:procodeOpenModal="promocodeModal" ref="navRight"></nav-right>
          </div>
        </nav>
      </div>
    </header>
    <!-------New MOdal------->
    <div class="modal fade custlocation-modal" id="deliveryModal" ref="deliveryModalModal" tabindex="-1"
      aria-labelledby="deliveryModallabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content location-modal">
          <div class="modal-header">
            <h4 class="modal-title" id="filterLabel">{{ $t('Select Location') }}</h4>
            <!-- <button type="button" class="close btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <button type="button" class="close btn-close" data-bs-dismiss="modal" aria-label="Close"
              @click="closeAddresModel()"></button>
          </div>
          <div class="modal-body text-center">
            <div v-if="checkpage != 'review_page'">
              <div class="col-md-12 p-0">
                <div class="position-relative">
                  <div class="search-box d-flex">
                    <div class="position-relative w-100">

                      <vue-google-autocomplete v-if="$auth.google_map_key" id="map" class="form-control" type="text"
                        v-model="addresses" v-on:placechanged="getAddressData"
                        :placeholder="$t('Search for an area or Location name')"
                        :types="['address', 'locality', 'sublocality']"
                        :country="$auth.setting.country_code_type"></vue-google-autocomplete>
                      <i class="fas fa-search search-icon"></i>
                      <div class="resturant-details current-address">
                        <span @click="locatorButtonPressed">
                          <svg width="25" height="25" viewBox="0 0 512 512" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M255.863 168.699C207.816 168.699 168.723 207.793 168.723 255.844C168.723 303.891 207.816 342.984 255.863 342.984C303.914 342.984 343.008 303.891 343.008 255.844C343.008 207.793 303.914 168.699 255.863 168.699Z"
                              fill="#FF8300" />
                            <path
                              d="M497.003 240.843H441.948C434.679 149.84 361.866 77.0233 270.859 69.7577V14.9961C270.859 6.71483 264.144 0 255.863 0C247.582 0 240.867 6.71483 240.867 14.9961V69.7577C149.859 77.0233 77.0467 149.84 69.7772 240.843H14.9961C6.71483 240.843 0 247.558 0 255.843C0 264.124 6.71483 270.839 14.9961 270.839H69.7772C77.0467 361.843 149.859 434.659 240.867 441.929V496.687C240.867 504.968 247.582 511.683 255.863 511.683C264.148 511.683 270.859 504.968 270.859 496.687V441.929C361.866 434.659 434.683 361.843 441.948 270.839H497.003C505.284 270.839 511.999 264.124 511.999 255.843C511.999 247.558 505.284 240.843 497.003 240.843V240.843ZM255.863 412.538C169.461 412.538 99.1678 342.242 99.1678 255.843C99.1678 169.441 169.461 99.1443 255.863 99.1443C342.265 99.1443 412.558 169.441 412.558 255.843C412.558 342.242 342.265 412.538 255.863 412.538Z"
                              fill="#FF8300" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <b-spinner v-if="is_spinning" type="grow" label="Spinning"></b-spinner>
              </div>
            </div>
            <div class="col-md-12 p-0" v-if="$auth.user && $auth.user.token">
              <div class="resturant-details mt-0 saved-addres">
                <h4 class="text-start">{{ $t('Saved Address') }}</h4>
              </div>
              <div class="orders-inner-main orders-inner-main-nw plr15" v-if="getAddresses.length > 0">
                <div class="orders-lst">
                  <div class="inner-data">
                    <div class="saved-address-main">
                      <div class="d-flex justify-content-between" v-for="(addres, index) in this.getAddresses"
                        :key="index" @click="selectDefaultAddress(addres)">
                        <div class="saved-address w-100 nav-saved-address 8">
                          <p class="text-capitalize mb-1">{{ $t(addres.adress_clarification) }}</p>
                          <span v-if="addres.flat_no == null || addres.flat_no == ''">{{ addres.house_name + " "
                            + addres.Shiping_address }}</span>
                          <span v-else>{{ addres.flat_no + ", " + addres.house_name + ", " + addres.Shiping_address
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="order-offers addrs">
                <p>{{ $t('No Address Found') }}</p>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="$auth.user && $auth.user.token">
            <div class="bottom-stiky border-0 w-100">
              <div class="full-btn " @click="addNewAddress()">
                <a href="javascript:void(0)" class="w-100">{{ $t('Add New Address') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------End MOdal-------->
    <!-------Add New Address New MOdal------->
    <div class="modal fade custlocation-modal" id="deliveryAddressModal" tabindex="-1"
      aria-labelledby="deliveryAddressModallabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content location-modal">
          <div class="modal-header">
            <h4 class="modal-title" id="filterLabel"></h4>
            <!-- <a data-dismiss="modal" class="lnk" @click="addAddressShow = false">{{$t('Close')}}</a> -->
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
              @click="addAddressShow = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body nw-addrs">
            <AddAddress v-on:closedAddAddress="saveClosedAddress"
              v-if="this.$route.name == 'review-cart' && addAddressShow"></AddAddress>
          </div>
        </div>
      </div>
    </div>
    <!-------Add New Address New MOdal------->
    <!--------All Promocode Modal start---------->
    <div class="modal fade order-placed" id="navPromocodeModal" tabindex="-1" aria-labelledby="promocodeModallabel"
      aria-hidden="true" ref="promocodeModalRef" style="z-index: 9999;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="filterLabel">{{ $t("Offers") }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" v-if="promoCodes.length > 0">
            <div class="order-item d-flex align-items-center justify-content-between"
              v-for="(offers, index) in this.promoCodes" :key="index">
              <div class="d-flex align-items-start">
                <div class="smallimg-squre"><img :src="offers.coupon_image" /></div>
                <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                  <div class="orders-status-main">
                    <h6 class="mb-0">{{ offers.coupon_name }}</h6>
                    <p class="mt-1" :id="'shortdec' + offers.id" style="display:block;">
                      {{ (offers.description.substring(0, 54)) }}
                      <a v-if="offers.description && offers.description.length > 54" href="javascript:void(0)"
                        @click="readMore(offers.id)" class="readmore">...more</a>
                    </p>
                    <p :id="'moredesc' + offers.id" style="display:none;">{{ offers.description }}
                      <a href="javascript:void(0)" @click="readMore(offers.id)" class="readless">less</a>
                    </p>
                    <div class="promocode mt-2">
                      <p>{{ $t('Code') }}: <span class="m-promocode">{{ offers.coupon_code }}</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <a href="javascript:void(0)" class="cart-btn" @click="doCopy(offers.coupon_code)">{{ $t('Copy') }}</a>
            </div>
          </div>
          <!-- <div v-else class="modal-body text-center"><p>{{$t('No Offers Available')}}</p></div> -->
          <div v-else class="home-res-not-found my-auto">
            <div class="nostr-img-cls">
              <!-- <img src="@/assets/images/no_promocode.png" />               -->
              <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="24" y="130" width="7" height="1" rx="0.5" fill="#FF8300" />
                <rect x="34" y="130" width="86" height="1" rx="0.5" fill="#FF8300" />
                <rect x="122" y="130" width="4" height="1" rx="0.5" fill="#FF8300" />
                <path
                  d="M80 84.5H106C108.485 84.5 110.5 86.5147 110.5 89V130.5H75.5V89C75.5 86.5147 77.5147 84.5 80 84.5Z"
                  stroke="#FF8300" />
                <path d="M47 58.5H93C95.4853 58.5 97.5 60.5147 97.5 63V130.5H42.5V63C42.5 60.5147 44.5147 58.5 47 58.5Z"
                  fill="white" stroke="#FF8300" />
                <line x1="34.3537" y1="49.7104" x2="40.8333" y2="56.19" stroke="#FF8300" stroke-linecap="round"
                  stroke-linejoin="round" />
                <line x1="46.8974" y1="44.6124" x2="44.5257" y2="53.4636" stroke="#FF8300" stroke-linecap="round"
                  stroke-linejoin="round" />
                <line x1="0.5" y1="-0.5" x2="9.66346" y2="-0.5"
                  transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 28 62.5439)" stroke="#FF8300"
                  stroke-linecap="round" stroke-linejoin="round" />
                <line x1="51.5" y1="91.5" x2="75.5" y2="91.5" stroke="#FF8300" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <line x1="51.5" y1="101.5" x2="65.5" y2="101.5" stroke="#FF8300" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <line x1="51.5" y1="83.5" x2="84.5" y2="83.5" stroke="#FF8300" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <rect x="102" y="65" width="19" height="1" rx="0.5" fill="#FF8300" />
                <rect x="12" y="95" width="19" height="1" rx="0.5" fill="#FF8300" />
                <rect x="109" y="77" width="19" height="1" rx="0.5" fill="#FF8300" />
                <circle cx="137.828" cy="107.828" r="2.32787" stroke="#FF8300" />
                <circle cx="77.8279" cy="47.8279" r="2.32787" stroke="#FF8300" />
                <circle cx="127.828" cy="47.8279" r="2.32787" stroke="#FF8300" />
                <circle cx="17.8279" cy="117.828" r="2.32787" stroke="#FF8300" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.1986 70.3995C15.9777 70.1787 15.6196 70.1787 15.3988 70.3995L13.7991 71.9992L12.1994 70.3995C11.9785 70.1787 11.6204 70.1787 11.3995 70.3995C11.1787 70.6204 11.1787 70.9785 11.3995 71.1994L12.9992 72.7991L11.3995 74.3987C11.1787 74.6196 11.1787 74.9777 11.3995 75.1986C11.6204 75.4194 11.9785 75.4194 12.1994 75.1986L13.7991 73.5989L15.3988 75.1986C15.6196 75.4194 15.9777 75.4194 16.1986 75.1986C16.4195 74.9777 16.4195 74.6196 16.1986 74.3987L14.5989 72.7991L16.1986 71.1994C16.4195 70.9785 16.4195 70.6204 16.1986 70.3995Z"
                  fill="#FF8300" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M56.6678 34.6119C56.8346 34.3478 56.7558 33.9985 56.4918 33.8317L54.5793 32.6232L55.7877 30.7107C55.9546 30.4467 55.8758 30.0973 55.6117 29.9305C55.3477 29.7636 54.9984 29.8424 54.8315 30.1065L53.623 32.019L51.7106 30.8105C51.4465 30.6437 51.0972 30.7225 50.9303 30.9865C50.7635 31.2506 50.8423 31.5999 51.1063 31.7667L53.0188 32.9752L51.8104 34.8877C51.6435 35.1518 51.7223 35.5011 51.9864 35.6679C52.2504 35.8348 52.5997 35.756 52.7666 35.4919L53.9751 33.5794L55.8875 34.7879C56.1516 34.9548 56.5009 34.876 56.6678 34.6119Z"
                  fill="#FF8300" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M143.787 71.3934C143.787 71.0811 143.534 70.8279 143.221 70.8279L140.959 70.8279L140.959 68.5656C140.959 68.2532 140.706 68 140.393 68C140.081 68 139.828 68.2532 139.828 68.5656L139.828 70.8279L137.566 70.8279C137.253 70.8279 137 71.0811 137 71.3934C137 71.7058 137.253 71.959 137.566 71.959L139.828 71.959L139.828 74.2213C139.828 74.5337 140.081 74.7869 140.393 74.7869C140.706 74.7869 140.959 74.5337 140.959 74.2213L140.959 71.959L143.221 71.959C143.534 71.959 143.787 71.7058 143.787 71.3934Z"
                  fill="#FF8300" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M108.787 21.3934C108.787 21.0811 108.534 20.8279 108.221 20.8279L105.959 20.8279L105.959 18.5656C105.959 18.2532 105.706 18 105.393 18C105.081 18 104.828 18.2532 104.828 18.5656L104.828 20.8279L102.566 20.8279C102.253 20.8279 102 21.0811 102 21.3934C102 21.7058 102.253 21.959 102.566 21.959L104.828 21.959L104.828 24.2213C104.828 24.5337 105.081 24.7869 105.393 24.7869C105.706 24.7869 105.959 24.5337 105.959 24.2213L105.959 21.959L108.221 21.959C108.534 21.959 108.787 21.7058 108.787 21.3934Z"
                  fill="#FF8300" />
              </svg>
              <h6>{{ $t('No Offers Available') }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--------All Promocode Modal End---------->
    <!--------All Store Modal start---------->
    <Stores v-if="isStore" v-on:storeModal="closeStoreModal" />
    <!-- <div class="modal fade" id="navStoreModal" tabindex="-1"  aria-labelledby="storeModallabel" aria-hidden="true" ref="storeModalRef" style="z-index: 9999;" v-if="this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && isStore" >       -->
    <!-- <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="filterLabel">{{ $auth.setting && $auth.setting.restaurant_name }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="hme-res-itm">
                    <div class="resturant-item-main1" v-if="stores && stores.length > 0 && $auth && $auth.getVendorId() > 0">
                      <item v-for="item in stores" :key="item.restaurant_id" :item="item" 
                        :isPickupDelivery="isPickupDelivery"
                        :isSchedule="$auth.setting.is_enable_schedule_order_after_business_hours"
                        :webVendorLayout="1"
                        components="navbar" 
                        >
                      </item>                      
                    </div>
                    <div v-else class="modal-body text-center"><p> <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span></p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div> -->
    <!-- </div> -->
    <!--------All Promocode Modal End---------->
  </div>
</template>
  
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SideBar from "@/components/SideBar.vue";
import NavRight from "@/components/NavRight.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import config from "../config/index";
import AddAddress from "@/components/AddAddress.vue";
import Stores from "@/components/Stores.vue";

export default {
  components: {
    SideBar,
    NavRight,
    VueGoogleAutocomplete,
    AddAddress,
    Stores
  },
  props: ['page', "checkpage", "settingData", "singleRestaurant"],
  data() {
    return {
      googleLoaded: false,
      geoaddress: "",
      getAddresses: [],
      loading: true,
      marker: { position: { lat: 23, lng: 72 } },
      center: { lat: 23, lng: 72 },
      mapOptions: { disableDefaultUI: true },
      address: "",
      locality: "",
      city: "",
      state: "",
      latitude: "",
      longitude: "",
      addresses: "", //Input type in display for field
      token: "",
      new_address: "",
      config: config,
      latlng: "",
      userId: "",
      rightClass: '',
      defaultAddress: '',
      show: true,
      is_pick_up: true,
      is_scrren_hide_mobile: (window.screen.width < 1024 ? true : false),
      is_spinning: false,
      onclick: '',
      marque_text: '',
      direction: '',
      addAddressShow: false,
      promoCodes: [],
      adressClarification: '',
      isStore: false
    };
  },

  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),
    ...mapGetters("address", ["addressList", "primaryAddress"]),
  },

  watch: {
    settingData: function () {
      this.is_pick_up = this.settingData.is_pickup_enable
    }
  },

  beforeCreate() {
    let localCart = localStorage.getItem("cart");
    if (localCart && localCart != '' && localCart != null) {
      this.$store.commit("product/setCart", JSON.parse(localCart));
    }
    let pickupDeliveryFlag = localStorage.getItem("pickupDeliveryFlag");
    if (pickupDeliveryFlag) {
      this.$store.commit("order/pickupdelivery", pickupDeliveryFlag);
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.$route.name == 'home' || this.$route.name == 'review-cart') {
        if (this.$auth.setting && this.$auth.setting.is_system_available == '1') {
          if (!localStorage.getItem("latitude")) {
            $('.location-modal').addClass('heightModal');
            $('#deliveryModal').modal('show');
          } else {
            this.address = localStorage.getItem("address");
          }
        }
        if (this.$auth.user) {
          this.token = this.$auth.user.token;
          this.userId = this.$auth.user.user_id;
          if (this.userId) {
            // if(this.address == '' || this.address == null){
            //   this.getPrimaryAddrs();
            // }
            this.getPrimaryAddrs();
          } else {
            if (!this.is_scrren_hide_mobile) {
              if (localStorage.getItem("latitude")) {
                this.geolocate();
              }
            }
          }
          this.AddressesList();
        }
      }
      // this.marque_text = this.$auth.setting.terminology && this.$auth.setting.terminology[0] && this.$auth.setting.terminology[0].marque_text;
      if (this.$store.state.lang.locale == "ar") {
        this.direction = 'right';
      } else {
        this.direction = 'left';
      }
      if (this.marque_text != 'null' && this.marque_text != '') {
        $('.header-top').css("padding-top", "95px");
        $('.searchbar-item.mastr-search').css("top", "86px");
      }
    }, 1000);

  },
  methods: {
    ...mapActions("restaurant", ["getRestaurants"]),
    ...mapActions("address", ["getAddress", "removeAddress", "setPrimaryAddress", "getPrimaryAddress"]),
    ...mapActions("promocode", ["promocodeRedeem", "updatePromoCode"]),
    ...mapMutations("address", ["primaryAddressMutation"]),
    ...mapMutations("order", ["pickupdelivery"]),

    navtoggleCart() {
      this.$emit("toggleNavCart", 'yes');
    },
    /**
     * 
     */
    addNewAddress() {
      this.addAddressShow = true;
      $('#deliveryModal').modal('hide');
      if (this.$route.name == 'review-cart') {
        $('#deliveryAddressModal').modal('show');
      } else {
        this.$router.push({ name: "add-address" });
      }
    },

    deliveryModalAddress() {
      this.is_spinning = false;
      if (this.$auth && this.$auth.user && this.$auth.user.user_id) {
        this.AddressesList();
        $('.location-modal').addClass('heightModal');
      }
      $('#deliveryModal').modal('show');
    },

    locatorButtonPressed() {
      this.is_spinning = true;
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
        };
        localStorage.setItem("latitude", position.coords.latitude);
        localStorage.setItem("longitude", position.coords.longitude);
        localStorage.setItem("userCurrentlatitude", position.coords.latitude);
        localStorage.setItem("userCurrentlongitude", position.coords.longitude);
        $('#deliveryModal').modal('hide');
        this.locateAddress(position.coords.latitude, position.coords.longitude);
      });
      this.onclick = 'yes';
      localStorage.setItem("geolocation", 'yes');
    },

    /**
     * 
     * @param {*} e 
     * @param {*} adress_clarification 
     * @param {*} flat_no 
     * @param {*} house_name 
     * @param {*} Shiping_address 
     * @param {*} latitude 
     * @param {*} longitude 
     * @param {*} city 
     * @param {*} state 
     */
    OnAddressList(e, adress_clarification, flat_no, house_name, Shiping_address, latitude, longitude, city, state) {
      this.onclick = 'yes';
      var getLocatinData = {
        city: city,
        state: state,
        lat: latitude,
        lng: longitude,
        onclick: this.onclick
      };
      this.address = flat_no + "," + house_name + "," + Shiping_address;
      this.adressClarification = adress_clarification;
      localStorage.setItem("address", this.address);
      localStorage.setItem("city", city);
      localStorage.setItem("state", state);
      localStorage.setItem("latitude", latitude);
      localStorage.setItem("longitude", longitude);
      this.$emit("getLatLong", getLocatinData);
      $('.location-modal').addClass('heightModal');
      $('#deliveryModal').modal('hide');
    },

    /**
     * 
     */
    AddressesList() {
      if (this.addressList && this.addressList.length > 0) {
        this.loading = false;
        this.getAddresses = this.addressList;
      } else {
        this.loading = true;
        this.getAddress({
          devicetoken: "",
          devicetype: "",
          uniqueid: "",
          type: "",
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.getAddresses = data.Result;
          } else if (data.code == 101) {
            this.loading = false;
          }
        });
      }
    },

    /**
     * 
     */
    geolocate() {
      if (!localStorage.getItem("address")) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.marker.position = {
            lat: parseFloat(position.coords.latitude),
            lng: parseFloat(position.coords.longitude),
          };
          this.locateAddress(position.coords.latitude, position.coords.longitude);
        });
      } else {
        this.address = localStorage.getItem("address");
        this.locateAddress(localStorage.getItem("latitude"), localStorage.getItem("longitude"));
      }
    },

    /**
     * 
     * @param {*} lat 
     * @param {*} lng 
     */
    locateAddress(lat, lng) {
      var getLocatinData = { city: this.city, state: this.state, lat: lat, lng: lng, onclick: this.onclick };
      $('#deliveryModal').modal('hide');
      this.$emit("getLatLong", getLocatinData);
      if (!this.$auth.google_map_key) {
        return false;
      }
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          this.address = results[0].formatted_address;
          this.adressClarification = ''
          var value = this.address.split(", ");
          var value1 = this.address.split(" ");
          var count = value.length;
          var count1 = value1.length;
          this.locality = value[count - 4];
          this.state = value1[count1 - 3];
          this.city = value[count - 3];
          this.zip_code = value1[count1 - 2];
          this.latitude = this.marker.position.lat;
          this.longitude = this.marker.position.lng;
          localStorage.setItem("address", this.address);
          localStorage.setItem("latitude", this.latitude);
          localStorage.setItem("longitude", this.longitude);
          localStorage.setItem("city", this.city);
          localStorage.setItem("state", this.state);
        }
      });
      this.address = localStorage.getItem("address");
    },

    /**
     * 
    */
    getAddressData(addressData, placeResultData) {
      this.onclick = 'yes';
      var latlongs = {
        city: this.city,
        state: this.state,
        lat: addressData.latitude,
        lng: addressData.longitude,
        onclick: this.onclick
      };
      this.addresses = placeResultData.formatted_address;
      this.address = placeResultData.formatted_address;
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
      this.marker.position = {
        lat: parseFloat(addressData.latitude),
        lng: parseFloat(addressData.longitude),
      };
      var value = this.address.split(", ");
      var value1 = this.address.split(" ");
      var count = value.length;
      var count1 = value1.length;
      this.locality = value[count - 4];
      this.state = value1[count1 - 3];
      this.city = value[count - 3];

      localStorage.setItem("address", this.addresses);
      localStorage.setItem("latitude", this.latitude);
      localStorage.setItem("longitude", this.longitude);
      localStorage.setItem("city", this.city);
      localStorage.setItem("state", this.state);
      localStorage.setItem("geolocation", 'yes');
      localStorage.setItem("userCurrentlatitude", this.latitude);
      localStorage.setItem("userCurrentlongitude", this.longitude);
      this.$emit("getLatLong", latlongs);
      $('#deliveryModal').modal('hide');
      this.addresses = "";
    },

    //Set Default Location
    selectDefaultAddress(address) {
      this.setPrimaryAddress({
        user_id: this.userId,
        address_id: address.shiping_id,
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
      }).then((data) => {
        if (data.code == 200) {
          localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id));
          // if(localStorage.getItem("restaurantId") == null){
          //   localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id));
          // }else{
          //   if(localStorage.getItem("restaurantId") != this.base64_encode(data.Result.restaurant_id)){
          //     localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id));
          //   }
          // }
          this.address = address.flat_no + " " + address.house_name + " " + address.Shiping_address;
          this.adressClarification = address.adress_clarification;
          if (localStorage.getItem("latitude") != address.latitude) {
            localStorage.setItem("address", this.address);
            localStorage.setItem("latitude", address.latitude);
            localStorage.setItem("longitude", address.longitude);
            localStorage.setItem("city", address.city);
            localStorage.setItem("state", address.state);
            this.$store.commit("address/primaryAddressMutation", address);
            var getLocatinData = {
              city: address.city,
              state: address.state,
              lat: address.latitude,
              lng: address.longitude,
              onclick: 'yes'
            };
            this.$emit("getLatLong", getLocatinData);
            window.$("#deliveryModal").modal("hide");
          }
          this.$emit("sendDefaultAddress", address);
          window.$("#deliveryModal").modal("hide");
        } else {
          this.$emit("sendDefaultAddress", address);
          window.$("#deliveryModal").modal("hide");
        }
      });
    },

    //Get check delivery
    getDelivery(value) {
      localStorage.setItem('orderUploadImages', '');
      this.$emit("isDelivery", value);
      this.$store.commit("order/pickupdelivery", value);
      localStorage.setItem('pickupDeliveryFlag', value);
      if (value == '1') {
        this.rightClass = '';
      } else {
        this.rightClass = 'right';
      }
    },

    //Get address for API call
    getPrimaryAddrs() {
      if (Object.keys(this.primaryAddress).length > 0) {
        this.address = this.primaryAddress.flat_no + " " + this.primaryAddress.house_name + " " + this.primaryAddress.Shiping_address;
        this.adressClarification = this.primaryAddress.adress_clarification;
        this.shiping_id = this.primaryAddress.shiping_id;
        this.$emit("sendDefaultAddress", this.address);
      } else {
        this.getPrimaryAddress({
        }).then((data) => {
          if (data.code == 200) {
            this.address = data.Result.flat_no + " " + data.Result.house_name + " " + data.Result.Shiping_address;
            this.adressClarification = data.Result.adress_clarification;
            this.selectDefaultAddress(data.Result);
            this.shiping_id = data.Result.shiping_id;
            localStorage.setItem("latitude", data.Result.latitude);
            localStorage.setItem("longitude", data.Result.longitude);
          } else {
            this.$emit("sendDefaultAddress", '');
          }
        });
      }
    },

    saveClosedAddress(value) {
      this.addAddressShow = false;
      window.$('#deliveryAddressModal').modal('hide');
      $('#deliveryModal').modal('hide');
      this.$emit("sendDefaultAddress", value);
      //this.deliveryModalAddress();
    },

    /** */
    promocodeModal() {
      let deliveryType = "Delivery";
      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        deliveryType = "Delivery";
      } else {
        deliveryType = "Pickup";
      }
      let usrid = '';
      if (this.$auth.user && this.$auth.user.user_id) {
        usrid = this.$auth.user.user_id;
      }
      this.updatePromoCode({
        vendor_id: this.$auth.getVendorId(),
        coupon_type: deliveryType,
        is_langauge: this.$store.state.lang.locale,
        user_id: usrid,
        cityname: localStorage.getItem("city")
      }).then((data) => {
        if (data.code == 200) {
          this.promoCodes = data.Result;
        } else {
          this.promoCodes = [];
        }
        $("#navPromocodeModal").modal("show");
      });
    },

    /**
     * 
     * @param {*} el 
     */
    doCopy(el) {
      this.isSelectCoupon = el;
      this.$copyText(el).then(function (e) {
        navigator.clipboard.writeText(e.text);
      })
      this.$toasted.success(this.$t("Promocode Copied to clipboard."), { duration: 2000 });
    },

    closeStoreModal() {
      this.isStore = false;
    },

    /**
    * Get setting lat lng when Pop up modal close by default.
    */
    closeAddresModel() {
      if (!localStorage.getItem("latitude") && !localStorage.getItem("longitude")) {
        if (!this.$auth.google_map_key) {
          return false;
        }
        var lat = parseFloat(this.$auth.setting.system_latitude);
        var lng = parseFloat(this.$auth.setting.system_longitude);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
          if (status === "OK") {
            this.address = results[0].formatted_address;
            this.adressClarification = ''
            var value = this.address.split(", ");
            var value1 = this.address.split(" ");
            var count = value.length;
            var count1 = value1.length;
            this.locality = value[count - 4];
            this.state = value1[count1 - 3];
            this.city = value[count - 3];
            this.zip_code = value1[count1 - 2];
            this.latitude = lat;
            this.longitude = lng;
            localStorage.setItem("address", this.address);
            localStorage.setItem("latitude", this.latitude);
            localStorage.setItem("longitude", this.longitude);
            localStorage.setItem("city", this.city);
            localStorage.setItem("state", this.state);
            this.locateAddress(localStorage.getItem("latitude"), localStorage.getItem("longitude"));
            var getLocatinData = { city: this.city, state: this.state, lat: lat, lng: lng, onclick: "yes" };
            this.$emit("getLatLong", getLocatinData);
          }
        });
        this.address = localStorage.getItem("address");
      }
    }

  },
};
</script>
<style scoped>   #marquee {
     position: relative;
     width: 100%;
     background: #eee;
     overflow: hidden;
     margin: 0px;
     display: inline-block;
     vertical-align: top;
   }

   #marquee p {
     font-family: 'Poppins', sans-serif;
     font-weight: 300;
     color: var(--theme-font-primary);
     font-size: 13px !important;
     margin: 0;
     padding: 5px 0;
   }

   @media only screen and (max-width:767px) {
     #marquee {
       margin: 0;
     }
   }

   .logo-container {
     width: 100%;
     height: 64px;
     background: #fff;
     border-radius: 10px;
     padding: 0;
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .logo-container .logo-holder a {
     text-decoration: none !important;
     display: inline-block;
   }

   .logo-holder.logo-4 {
     text-align: center;
   }

   .logo-4 h3 {
     color: rgba(var(--theme-primary));
     font-weight: 300;
     font-size: 50px;
     line-height: 0.65;
     font-family: 'Lobster', cursive;
   }

   .logo-4 p {
     font-size: 14px;
     margin-left: 50px;
     color: #545454;
     font-weight: 400;
     text-transform: capitalize;
     font-style: italic;
     font-family: 'Mansalva', cursive;
   }

   .modal-body .resturant-item-main1 {
     display: flex;
     flex-wrap: wrap;
     gap: 15px;
   }</style>